@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  &:before {
    color: #6a2876;
  }
}
.app-bg {
  background: rgba(172, 163, 178, 0.1);
}

.first-capital:first-letter {
  text-transform: uppercase;
}
.app-hero {
  background-attachment: fixed;
  background: #44185c url("../image/footer_right.png") no-repeat 80% 100%;
  background-size: 500px;
  // position: relative;
  // isolation: isolate;
  // &::after{
  //   content: '';
  //   bottom: 0;
  //   height: 70px;
  //   position: absolute;
  //   width: 100%;
  //   background: white;
  //   -webkit-mask-image: url("../image/triangle-up.svg");
  //   mask-image: url("../image/triangle-up.svg");
  // }
}

.chakra-link.side-nav-item.active {
  color: #44185c;
  font-weight: 600;
}

.testimonial-wrapper {
  background: #44185c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #e94057,
    #44185c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #e94057,
    #44185c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.feature-icon {
  // background: #7b4397;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #dc2430, #7b4397);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #dc2430, #7b4397); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background: #eb3349; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f45c43,
    #eb3349
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f45c43,
    #eb3349
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 50%;
  padding: 16px;
}

.tds-blog-content {
  p {
    padding-bottom: 16px;
    padding-top: 6px;
  }
}
